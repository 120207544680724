import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Dashbot had the pleasure of interviewing Paul Jackson about his work with the BBC and what he learned about design through developing voice skills for children.`}</p>
    <h2>{`Can you introduce yourself?`}</h2>
    <p>{`I’m a Senior UX Designer at the BBC. I’ve been designing, prototyping, and building products in the digital space for about 15 years now. I’ve worked with clients like Disney and Nintendo at agencies and had experience with ecommerce as well. For the past year, I’ve been leading the design for the BBC Kids skill, which is an audio-first experience that allows children as young as three to choose the games, stories, and songs they love from their favorite brands.`}</p>
    <h2>{`Why did the BBC decide to make a voice skill?`}</h2>
    <p>{`They’ve been on board with voice for a while now. The BBC put a really sizable team together to back this development, and they’re really committed to voice as a platform. I think what really drives this is the fact that the BBC doesn’t have any commercial interests as a public organization. They see it as their mission to offer trust in a marketplace that’s driven by commerce. The public trusts the BBC for news, sports reporting, entertainment, and children’s content as well.`}</p>
    <h2>{`Why focus on children’s content?`}</h2>
    <p>{`We wanted to start in a difficult place because we knew moving up from that was going to be more straightforward. We’re designing for children aged three to six, and they’re only just learning how to speak. Children aren’t good at speaking and voice devices aren’t good at listening. Those are some tough constraints, but they allow us to learn a hell of a lot about voice. We’ve been really rigorous in our research and design because we don’t make assumptions when designing for children. The principles we’ve learned are really universal and transferable to adults. We couldn’t have gotten insights for children if we had designed for adults first.`}</p>
    <p>{`Where else are you applying the lessons you’ve learned from designing for kids within the BBC?`}</p>
    <hr></hr>
    <p>{`We’ve condensed what we learned into 10 design principles and they’ve been shared widely with the larger voice team. One of those principles is that you have to let people know their options before you ask a question because they’ll want to answer as soon as they hear that question mark. We don’t ask rhetorical questions. It’s the little things like that that translate into really solid voice design, and they’re being applied anywhere we’re writing scripts and interactions.`}</p>
    <h2>{`How has the way you approach design shifted since working on voice?`}</h2>
    <p>{`We’re still doing similar things, just in different ways. I follow a similar UX design process, but instead of doing wireframes and mockups, we’re recording audio demos and simulating instant message conversations back and forth. We found that this combination to be really useful for sharing our designs with stakeholders because they can see and hear it at the same time. Being at the BBC, we’re surrounded by actors. I borrow a lot from them and do a lot of acting and role playing. We also now say our scripts out loud before writing them down or recording them.`}</p>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://twitter.com/madebymagnolia?lang=en"
        }}>{`Paul Jackson`}</a>{` presented with Sr. Product Manager `}<a parentName="em" {...{
          "href": "https://twitter.com/LisaVigar?lang=en"
        }}>{`Lisa Vigar`}</a>{` at our annual chatbot and voice conference SuperBot this year. You can watch their presentation `}<a parentName="em" {...{
          "href": "https://www.dashbot.io/2019/04/08/superbot-2019-bbc-kids/"
        }}>{`here`}</a>{`.`}</em></p>
    <h2>{`About Dashbot`}</h2>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.dashbot.io/"
        }}>{`Dashbot`}</a>{` is a conversational analytics platform that enables enterprises and developers to understand user behaviors, optimize response effectiveness, and increase user satisfaction through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and broadcast messaging for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support Alexa, Google Home, Facebook Messenger, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales/?ref=blog+paul+jackson+bbc"
      }}><br parentName="a"></br>
        {`Contact us for a demo `}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      